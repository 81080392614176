function truncateText() {
  const element = document.querySelectorAll('.container-four-swiper-text');

  for (let i = 0; i < element.length; i++) {
    const text = element[i].innerText;

    if (text.length > 180) {
      element[i].innerText = text.slice(0, 180) + '...';
    }
  }
}

if (window.innerWidth <= 768) {
  truncateText();
}
